import { getTextFromMenuNumberAndMenuValue } from '@/cruds/language.crud';

export const textStatus = async (validated) => {
	let value = null;

	await new Promise((resolve, reject) => {
		setTimeout(() => {
			resolve(getTextFromMenuNumberAndMenuValue(1008, validated));
			reject(false);
		}, 100);
	}).then((data) => {
		value = data;
	});

	return value;
};

export const classStatus = (validated) => {
	let value = 'not-submitted';

	if (validated === 1) {
		value = 'info';
	} else if (validated === 2) {
		value = 'pending';
	} else if (validated === 8) {
		value = 'validated';
	} else if (validated === 4 || validated === 16) {
		value = 'refused';
	}

	return value;
};

export const classDocumentStatus = (validated) => {
	let value = 'not-submitted';

	if (validated === 1) {
		value = 'refused';
	} else if (validated === 2) {
		value = 'pending';
	} else if (validated === 3) {
		value = 'info';
	} else if (validated === 4 || validated === 5) {
		value = 'validated';
	} else if (validated === 6) {
		value = 'sent';
	}

	return value;
};
