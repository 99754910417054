<template>
	<!-- Warning on delete -->
	<b-modal
		header-class="header-class-modal-doc-package"
		header-bg-variant="light"
		:title="FormMSG(1, 'Warning')"
		class="modal-warning"
		v-model="value"
		:modal-header-close="false"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		centered
	>
		<div>
			{{ message }}
		</div>
		<template #modal-footer>
			<div class="w-100">
				<b-row class="padding-c-1">
					<b-col cols="12">
						<div class="d-flex flex-row justify-content-center align-items-center w-100" style="gap: 20px">
							<b-button size="md" variant="outline-secondary" :disabled="watingSubmitForm" class="w-100-px" @click="$emit('dialog:cancel')">
								{{ FormMSG(2, 'No') }}
							</b-button>
							<b-button
								size="md"
								variant="light"
								class="w-138-px d-flex justify-content-center align-items-center bgc-grey-light"
								:disabled="watingSubmitForm"
								@click="confirmDialog"
							>
								<b-spinner v-show="watingSubmitForm" small />
								<div :class="`${watingSubmitForm ? 'pl-2' : ''}`" style="margin-top: 1px">
									{{ FormMSG(3, 'Yes') }}
								</div>
							</b-button>
						</div>
					</b-col>
				</b-row>
			</div>
		</template>
	</b-modal>
	<!-- Warning on delete -->
</template>

<script>
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'CommonWarningOnDeleteDialog',

	mixins: [globalMixin],

	props: {
		value: { type: Boolean, required: true },
		message: { type: String, required: true }
	},

	data() {
		return {
			watingSubmitForm: false
		};
	},

	methods: {
		confirmDialog() {
			this.watingSubmitForm = true;
			this.$emit('dialog:confirm');
			this.watingSubmitForm = false;
		}
	}
};
</script>

<style>
.custom-radius {
	border-radius: 8px !important;
}
</style>
