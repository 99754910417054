var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      staticClass: "modal-warning",
      attrs: {
        "header-class": "header-class-modal-doc-package",
        "header-bg-variant": "light",
        title: _vm.FormMSG(1, "Warning"),
        "modal-header-close": false,
        "hide-header-close": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        centered: "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "w-100" },
                [
                  _c(
                    "b-row",
                    { staticClass: "padding-c-1" },
                    [
                      _c("b-col", { attrs: { cols: "12" } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row justify-content-center align-items-center w-100",
                            staticStyle: { gap: "20px" },
                          },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "w-100-px",
                                attrs: {
                                  size: "md",
                                  variant: "outline-secondary",
                                  disabled: _vm.watingSubmitForm,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("dialog:cancel")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(_vm.FormMSG(2, "No")) +
                                    "\n\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _c(
                              "b-button",
                              {
                                staticClass:
                                  "w-138-px d-flex justify-content-center align-items-center bgc-grey-light",
                                attrs: {
                                  size: "md",
                                  variant: "light",
                                  disabled: _vm.watingSubmitForm,
                                },
                                on: { click: _vm.confirmDialog },
                              },
                              [
                                _c("b-spinner", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.watingSubmitForm,
                                      expression: "watingSubmitForm",
                                    },
                                  ],
                                  attrs: { small: "" },
                                }),
                                _c(
                                  "div",
                                  {
                                    class: `${
                                      _vm.watingSubmitForm ? "pl-2" : ""
                                    }`,
                                    staticStyle: { "margin-top": "1px" },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.FormMSG(3, "Yes")) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [_c("div", [_vm._v("\n\t\t" + _vm._s(_vm.message) + "\n\t")])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }